<template>
  <div class="circle-item">
    <img class="circle-avatar" :src="item.avatar" alt="">
    <div class="message" :class="!item.jkinfo ? 'mes1':'mes2'">
      <span class="message_til" v-html="item.title">{{ item.title }}</span>
      <div class="subTitle" v-if="isShowIntro">{{ item.jkinfo }}</div>
      <div v-if="item.circleStatisticVO" class="message_gfzs">{{ item.circleStatisticVO.memberCount || 0 }}人加入&nbsp;|&nbsp;{{
          item.circleStatisticVO.articleCount || 0
        }}帖子
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "circleItem",
  props: {
    // 是否显示简介
    isShowIntro: {
      type: Boolean,
      default: false
    },
    // 是否来自我的管理
    isComeManage: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    user: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    myGlCircle(item) {
      this.$emit('myGlCircle', item)
    },
    /**
     * 加入圈子按钮
     */
    joinBtn(item) {
      if (item.isJoinApply) {
        this.$emit('joinAppleBtn', item)
      } else {
        this.$emit('joinBtn', item)
      }
    },
    /**
     * 退出圈子按钮
     */
    handleQuitCircle(item) {
      this.$emit('quitCircle', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.circle-item {
  display: flex;
  flex-direction: row;
  align-items: center;


  .message_til {
    font-size: 14px;
    color: #000000;
  }

  //.mes1 {
  //  position: relative;
  //  top: -5px;
  //}
  //
  //.mes2 {
  //  position: relative;
  //  top: -9px;
  //}

  .message {
    display: flex;
    flex-direction: column;

    .subTitle {
      margin-top: 2px;
      font-size: 12px;
      color: #6d7278;
      letter-spacing: 0;
    }
  }

  .message_gfzs {
    margin-top: 2px;
    font-size: 12px;
    color: #6d7278;
  }

  .circle-avatar {
    width: 44px;
    height: 44px;
    margin-right: 6px;
    border-radius: 50%;
    object-fit: cover;
  }


  .right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    font-size: 13px;

    .sign {
      font-size: 18px;
    }
  }

  .right.join {
    color: #ffffff;
    background-color: $mainColor;
  }

  .right.mine {
    color: $moreColor;
  }

  .right.have-join {
    color: #6d7278;
  }

  .right_my {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon_box {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon_ri {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: #f60;
        border-radius: 50%;
      }

      .icon_tit {
        margin: 0 8px 0 4px;
        font-size: 12px;
        color: #fe5800;
      }
    }

    .icon_lis {
      width: 16px;
      height: 16px;
      display: inline-block;
      background: url('~@/assets/imgs/circle/Slice41@2x.png') no-repeat;
      background-size: cover;
    }
  }
}
</style>
