<template>
  <div class="flex-row topic_video">
      <img
        :src="
                formData.videoAddr +
                '?x-oss-process=video/snapshot,t_50,f_jpg,ar_auto'
              "
      />
      <TopRightDeleteIcon @click.native.stop="handleDeleteVideo"></TopRightDeleteIcon>
  </div>
</template>

<script>
import TopRightDeleteIcon from '@/components/topRightDeleteIcon'

export default {
  name: "index",
  components:{
    TopRightDeleteIcon
  },
  props: {
    formData: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    handleDeleteVideo () {
      this.$emit('deleteVideo')
    }
  }
}
</script>

<style lang="scss" scoped>
.topic_video {
  position: relative;
  display: inline-block;
  width: 200px;
  margin-top: 20px;

  img {
    width: 200px;
    object-fit: cover;
    overflow: hidden;
  }

}
</style>
