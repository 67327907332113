<template>
  <div class="autoInputContainer">
    <label v-if="label.length > 0" class="inputLable">{{ label }}</label>
    <div class="flex-row-h-center" @mouseenter="enter" @mouseleave="leave">
      <input
        type="text"
        v-autowidth="{maxWidth: '300px', minWidth: '210px', comfortZone: 0}"
        placeholder="请输入"
        class="autoInput"
        @input="update"
        :value="value"
        :maxlength="maxLength"
        @blur="inputBlur"
        @focus="inputFocus"
        ref="inputAutoWidth"
        :id="nodeRef"
      />
      <i class="el-icon-circle-close close" @click="clearValue" v-show="closeDisable"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoWidthInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    maxLength: {
      type: [Number],
      default: 15
    },
    label: {
      type: [String],
      default: ''
    },
    nodeRef:{
      type: [String],
      default: 'input'
    }
  },
  data () {
    return {
      closeDisable: true,
      isFocus: false // input框是否聚焦
    };
  },
  methods: {
    update (e) {
      this.$emit('input', e.target.value)
      if (e.target.value !== '') {
        this.closeDisable = true // 显示清空按钮
      }
    },
    clearValue () {
      this.$emit('input', '')
      this.$refs.input.focus()
    },
    inputBlur () {
      this.$emit('inputBlur')
    },
    inputFocus (e) {
      this.$emit('inputFocus')
      this.isFocus = true
      if (e.target.value !== '') {
        this.closeDisable = true
      }
    },
    // hover效果
    enter (e) {
      console.log(e)
      if (this.value !== '') {
        this.closeDisable = true
      }
    },
    leave (e) {
      console.log(e)
      this.closeDisable = false
    }
  }
};
</script>

<style lang="scss" scoped>
.autoInputContainer {
  position: relative;
  padding: 0;
  float: left;
  margin-right: 0px;

  label {
    font-size: 13px;
    display: inline-block;
    padding-right: 15px;;
  }

  .autoInput {
    box-sizing: border-box;
    color: $mainColor;
    display: inline-block;
    font-size: inherit;
    height: 24px;
    line-height: 12px;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: none;
    white-space: nowrap;
    font-size: 12px;
    padding: 0 0 0 2px;
  }

  .autoInput:hover {
    border-color: #FFFFFF
  }

  .close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    font-size: 14px;
    cursor: pointer;
    color: #DCDFE6;
  }

  .close:hover {
    color: #979b9f;
  }
}
</style>
