<template>
  <div class="flex-column" style="height: 100%;">
    <!--    title-->
    <HeaderTitle @cancelSelect="handleChangeItem"></HeaderTitle>
    <div class="flex-column" style="flex: 1;overflow-y: auto">
      <!--    我加入的-->
      <div class="my-join-list">
        <SelectCircle :selectItem="selectItem" v-if="myJoinList.length > 0 && isLoaded"
          @handleChangeItem="handleChangeItem"
          :circleList="myJoinList"></SelectCircle>
        <!-- todo 无数据展示 -->
        <div v-if="myJoinList.length === 0 && isLoaded" class="hotCircle_noData">
          <div class="noData_box">
            <div class="noData_img"></div>
            <div class="noData_qjr" @click="skipAllCircle">
              <span>暂未加入任何圈子</span>
              <span class="go-join"> 去加入 ></span>
            </div>
          </div>
        </div>
      </div>
      <!--    被禁言的圈子列表-->
      <div class="forbidden-list" v-if="myJoinList.length && myJoinListForbidden.length">
        <!--    被禁言的圈子列表提示-->
        <div class="forbidden-tips">
          下述加入的圈子已被禁言(无法同步发帖)
        </div>
        <div class="flex-column forbidden-item" v-for="forbiddenItem in myJoinListForbidden" :key="forbiddenItem.id">
          <CircleItem
            :is-hidden-right="true"
            :item="forbiddenItem"
            :user="user"
            :isShowIntro="false"
          ></CircleItem>
        </div>
      </div>
      <!--    热门圈子列表-->
      <div v-if="interestCircleList.length > 0" class="flex-column hot-list">
        <div class="hot-list-header">
          <span>你可能感兴趣的的圈子</span>
          <span class="complete" @click.stop="skipAllCircle"> 全部圈子 ></span>
        </div>
        <div class="hot-list-item" v-for="hotItem in interestCircleList" :key="hotItem.id">
          <CircleItem
            :item="hotItem"
            :user="user"
            @quitCircle="handleUpdateMyCircle"
            @joinCircle="handleUpdateMyCircle"
          ></CircleItem>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderTitle from './headerTitle'
import SelectCircle from '@/components/circle/selectCircle/index'
import CircleItem from '@/components/circle/circleItem'
import {
  getMyInterestCircle,
  getMyJoinListHaveForbidden,
  getMyJoinListNotForbidden
} from "@/api/circle";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {
    HeaderTitle,
    SelectCircle,
    CircleItem
  },
  props: {
    selectItem: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  data () {
    return {
      myJoinList: [],
      myJoinListForbidden: [],
      interestCircleList: [],
      isLoaded: false,
    }
  },
  created () {
    this.getMyJoinList()
    this.getMyJoinListForbidden()
    this.getMyLoveCircle()
  },
  methods: {
    // 加入或者退出圈子刷新我加入的圈子
    handleUpdateMyCircle () {
      this.getMyJoinList()
    },
    // 选择的数据更改时
    handleChangeItem (item) {
      if (item && Object.keys(item).length > 0) {
        this.$emit('change', item)
      } else {
        this.$emit('change', false)
      }
    },
    /**
     * todo 获取我加入的圈子列表,未禁言
     */
    async getMyJoinList () {
      try {
        const params = {
          pageNum: 1,
          pageSize: 20
        }
        const res = (await getMyJoinListNotForbidden(params)) || {}
        const {records: list} = res || []
        this.myJoinList = list
        // if (this.myJoinList.length === 0) {
        //   this.isRecommendShow = true
        // }
      } finally {
        this.isLoaded = true
      }
    },
    /**
     * todo 获取我加入的圈子列表，已禁言
     */
    async getMyJoinListForbidden () {
      const params = {
        pageNum: 1,
        pageSize: 20
      }
      const res = (await getMyJoinListHaveForbidden(params)) || {}
      const {records: list} = res || []
      this.myJoinListForbidden = list
    },
    /**
     * todo 获取你可能感兴趣的圈子列表，未加入的并且可直接加入的
     */
    getMyLoveCircle () {
      getMyInterestCircle().then((res) => {
        this.interestCircleList = res
      })
    },
    skipAllCircle () {
      this.$router.push({name: "circleMy"});
      // 设置选中下标
      localStorage.setItem("type", 1);
    }

  }
}
</script>

<style lang="scss" scoped>
.my-join-list {
  height: 346px;
  overflow-y: auto;

  .hotCircle_noData {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 30px;

    .noData_box {

      .noData_img {
        width: 212px;
        height: 114px;
        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .noData_qjr {
      font-size: 14px;
      font-weight: 500;
      color: #fe5800;
      margin-top: 7px;

      > span {
        font-size: 12px;
        font-weight: 500;
        color: #6d7278;
      }

      .go-join {
        color: $mainColor;
      }
    }
  }
}

.forbidden-list {
  padding-top: 16px;

  .forbidden-tips {
    margin: 0 16px 18px 16px;
    font-size: 14px;
    color: #6d7278;
  }

  .forbidden-item {

  }
}

.hot-list {
  .hot-list-header {
    display: flex;
    justify-content: space-between;
    width: 345px;
    margin-bottom: 24px;
    padding: 0 16px;
    margin-top: 20px;


    span {
      font-size: 12px;
      color: #6d7278;
    }

    .complete {
      color: #fe5800 !important;
    }

  }

  .hot-list-item {

  }
}
</style>
