<template>
  <div class="head-title">
    <div class="title">选择我的圈子</div>
    <div class="cancel-select" @click="cancelSelect">不选择任何圈子</div>
  </div>
</template>

<script>
export default {
  name: "headerTitle",
  methods: {
    cancelSelect() {
      this.$emit('cancelSelect', false)
    }
  }
}
</script>

<style scoped>
.head-title {
  color: #000000;
}

.title {
  margin-top: 16px;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
}

.cancel-select {
  font-size: 14px;
  padding-bottom: 10px;
  margin: 24px 20px 0;
}
</style>
