<template>
  <div class="hotCircle">
    <template v-if="circleList && circleList.length > 0">
      <div class="hot_Item">
        <van-radio-group
          class="group"
          v-model="selectIndex"
        >
          <van-cell-group>
            <van-cell
              v-for="(item, index) in circleList"
              :key="index"
              @click="handleClickItem(item)"
              clickable
            >
              <template #title>
                <CircleItem
                  :item="item"
                  :user="user"
                  :isShowIntro="isShowIntro"
                  :isComeManage="isComeManage"
                  @myGlCircle="myGlCircle"
                  @quitCircle="handleQuitCircle"
                  @joinAppleBtn="handleJoinAppleBtn"
                  @joinBtn="handleJoinBtn"
                ></CircleItem>
              </template>
              <template #right-icon>
                <van-radio :name="item.id" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CircleItem from './circleItem'
import {setApplyJoin, setQuitCircle} from '@/api/circle'

export default {
  components: {CircleItem},
  props: {
    // 是否显示简介
    isShowIntro: {
      type: Boolean,
      default: false
    },
    isRecommend: {
      type: Boolean,
      default: false
    },
    // 是否来自我的管理
    isComeManage: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 455
    },
    nodataHi: {
      type: Number,
      default: 144
    },
    ContSet: {
      type: Object,
      default: () => {
      }
    },
    circleList: {
      type: Array,
      default: () => []
    },
    noDataInfo: {
      type: Object,
      default: () => {
      }
    },
    selectItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      selectIndex: 0,
      isShowApply: false,
      isShowQuit: false,
      quitTips: '你确定要退出该圈子吗？',
      applyQuitItem: {}, // 当前要加入或退出的圈子item
      applyReason: '', // 申请加入原因
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    this.setSelectedId()
  },
  methods: {
    // 设置默认选择的圈子
    setSelectedId () {
      if (!this.selectItem.id) {
        return false
      }
      const selectItem = this.circleList.find((item) => item.id === this.selectItem.id)
      this.$nextTick(() => {
        if (selectItem.id) {
          this.selectIndex = selectItem.id
        }
      })
    },
    // 点击选中圈子
    handleClickItem (item) {
      this.selectIndex = item.id
      this.handleChangeItem(item)
    },
    /**
     * 监听选择项的变化
     */
    handleChangeItem (item) {
      if (item !== 0) {
        this.$emit('handleChangeItem', item)
      } else {
        this.$emit('handleChangeItem', false)
      }
    },
    /**
     * 申请/加入 右侧按钮
     */
    handleJoinBtn (item) {
      this.$emit('joinBtn', item)
      this.setApplyJoin(item)
    },
    skip () {
      // 全部圈子页面
      this.$router.push({name: 'circleMy'})
      // 设置选中下标
      localStorage.setItem('type', 1)
    },
    /**
     * 需要申请加入
     */
    handleJoinAppleBtn (item) {
      this.applyQuitItem = item
      this.isShowApply = true
    },
    handleConfirmApply () {
      this.applyReason = this.applyReason.trim()
      if (this.applyReason) {
        this.setApplyJoin(this.applyQuitItem)
      }
    },
    /**
     * 申请加入圈子接口
     * @returns {Promise<void>}
     */
    async setApplyJoin (item) {
      const data = {
        applyReason: this.applyReason,
        circleId: item.id
      }
      await setApplyJoin(data)
      if (item.isJoinApply) {
        this.$toast({
          message: '加入申请提交成功',
          position: 'top'
        })
      } else {
        this.$toast({
          message: '加入成功',
          position: 'top'
        })
      }
      this.circleList.forEach((childItem) => {
        if (childItem.id === item.id) {
          childItem.identity = 3
        }
      })
    },
    /**
     * 确认退出圈子
     */
    handleConfirmQuit () {
      this.setQuitCircle()
    },
    /**
     * 退出圈子接口
     */
    async setQuitCircle () {
      await setQuitCircle(this.applyQuitItem.id)
      this.circleList.forEach((childItem) => {
        if (childItem.id === this.applyQuitItem.id) {
          childItem.identity = 100
        }
      })
    },
    /**
     * 退出圈子
     */
    handleQuitCircle (item) {
      this.applyQuitItem = item
      if (!item.isPublic) {
        this.quitTips = '你确定要退出该私密圈子吗？退出后再加入需要圈主邀请'
      }
      this.isShowQuit = true
    },
    // 我管理的圈子退出
    myGlCircle (item) {
      this.$emit('myGlCircle', item)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-dialog__content {
    margin: 20px;

    .van-dialog__message.van-dialog__message--has-title {
      white-space: pre-wrap !important;
      font-size: 100px;
    }
  }

  .group {
    .van-radio__icon--checked {
      .van-icon-success {
        background-color: #fe5800;
        border-color: #fe5800;
      }
    }
  }
}


.colf6f6 {
  background: #f6f6f6;
  color: #6d7278;
}

.colfe5 {
  background: #fe5800;
  color: #fff;
}

.col6D7278 {
  color: #6d7278;
}

.reas {
  position: relative;
  top: -4px;
}

.hotCircle {
  background: #fff;

  .default-quit {
    text-align: center;
    font-family: 'PingFang SC';
    font-size: 14px;
    line-height: 23px;
    color: #222222;
  }

  .hotCircle_title {
    overflow: hidden;
    line-height: 20px;

    .hotCircle_join {
      font-size: 15px;
      float: left;
      color: #000000;
    }

    .hotCircle_circle {
      font-size: 12px;
      color: #6d7278;
      float: right;
    }
  }

  .hotCircle_list_box {
    //margin-top: 20px;
    //overflow: scroll;
  }


  .rightGx {
    width: 24px;
    height: 24px;
    background: url('~@/assets/imgs/circle/Slice43_32.svg');
    background: cover;
  }
}
</style>
