<template>
  <div class="createTopicView">
    <pageHead
      :title="isCircle ? `发布至圈子-${circleDetails.title}` : '发布至广场'"
    >
      <!--      <p class="submitBtn" slot="right" @click.prevent="handlePublic">{{ isForward ? '转发' : '发布' }}</p>-->
    </pageHead>
    <div class="createTopicView_editView">
      <div class="editView_box">
        <input
          @focus="keyboardUp2 = true"
          @blur="blurEvent2"
          class="createTopicView_editView_title"
          placeholder="请输入标题"
          v-if="!isForward"
          v-model="formData.title"
          @input="handleTitleInput"
          maxlength="24"
          ref="titleInput"
        />
        <div
          class="createTopicView_editView_content"
          :class="keyboardUp ? 'contentWrap' : ''"
        >
          <div class="selectedUserList" v-if="false">
            <div class="memberList">
              <div
                v-for="(item, index) in selectMemberList"
                :key="index"
                class="memberList_item"
              >
                <img
                  src="/images/common/clear.png"
                  @click.prevent="handleDelete(index)"
                />
                <p class="userTag">@{{ item.name }}</p>
              </div>
            </div>
          </div>
          <!-- todo  创建话题-->
          <div class="createTag" :key="keyValue" v-if="showCreateTag">
            <div
              class="tagLeft"
              @click.self="handleInputFocus(index)"
              v-for="(item, index) in [...topicList, '']"
              :key="index + item"
            >
              <span>#</span>
              <div
                v-text="item"
                class="tag-input"
                ref="tagInput"
                contenteditable="true"
                @keydown="handleKeydown($event, index)"
                @focus="keyboardUp3 = true"
                @blur="handleBlur(index)"
                @paste="topicPaste($event, index)"
              ></div>
              <div class="delete-topic">
                <span>#</span>
                <TopRightDeleteIcon @click.native.stop="deleteTopic(index)"></TopRightDeleteIcon>
              </div>
            </div>
          </div>
          <!-- todo  创建话题input-->
          <div v-if="topicObjList.length > 0 && false" class="createTag">
            <div
              class="tagLeft"
              :style="{marginLeft: (index + 1) % 2 ===0? '0' : 0 }"
              v-for="(itemObj, index) in topicObjList"
              :key="itemObj.id">
              <img
                src="/images/common/delete.png"
                @click.stop="deleteObjTopic(itemObj.id)"
                class="delete"
              />
              <span>#</span>
              <AutoWidthInput
                :nodeRef="'topicFiled' + (index + 1)"
                ref="topicFiled"
                @inputFocus="handleInputFocusAuto"
                @inputBlur="handleInputBlurAuto"
                @input="handleInputObjTopic"
                v-model="itemObj.value"></AutoWidthInput>
              <span>#</span>
            </div>
            <van-col v-if="false" class="button-container" :span="11">
              <van-button round @click.prevent="handleAddObjTag">添加</van-button>
            </van-col>
          </div>

          <van-row v-if="topicObjList.length > 0 && false" class="createTag">
            <van-col
              class="tagLeft"
              :style="{marginLeft: (index + 1) % 2 ===0? '0' : 0 }"
              v-for="(itemObj, index) in topicObjList"
              :key="itemObj.id" span="16">
              <img
                src="/images/common/delete.png"
                @click.stop="deleteObjTopic(itemObj.id)"
                class="delete"
              />
              <span>#</span>
              <van-field
                ref="topicFiled"
                :formatter="formatterTitle"
                maxlength="15"
                @input="handleInputObjTopic"
                v-model="itemObj.value"
                placeholder="请输入" />
              <span>#</span>
            </van-col>
            <van-col v-if="false" class="button-container" :span="11">
              <van-button round @click.prevent="handleAddObjTag">添加</van-button>
            </van-col>
          </van-row>

          <!-- <van-field
            @focus="keyboardUp = true"
            @blur="blurEvent"
            v-model="formData.content"
            rows="4"
            autosize
            type="textarea"
            maxlength="5000"
            :placeholder="
              isForward
                ? '说一说想法心得'
                : '即刻发声，有态度、有价值的社区需要你的共同参与…'
            "
            @input="handleContentInput"
          />
          -->
          <div
            @focus="keyboardUp = true"
            @blur="blurEvent"
            @DOMSubtreeModified="handleContentInput"
            placeholder="即刻发声，有态度、有价值的社区需要你的共同参与…"
            ref="inputs"
            contenteditable
            class="comment-input-box"
            @paste="paste"
          ></div>

          <!--  todo   投票信息-->
          <div class="vote_box" v-if="JSON.stringify(voteArr) != '{}'">
            <TopRightDeleteIcon @click.native.stop="removeVote"></TopRightDeleteIcon>
            <div class="bigBox" @click="handleToup">
              <div class="vote_til">
                <span class="til_1">{{ voteArr.title }}</span>
              </div>
              <div class="vote_fod_til">
                {{
                  voteArr.type === 1 ? '多选' : '单选'
                }}<span v-if="voteArr.type === 1">
                  丨{{ `最多${voteArr.changMore}项` }}</span
              >丨{{ voteArr.tpjzrq }}结束
              </div>
            </div>
            <ul class="vote_lis_box" v-if="titleLit">
              <li v-for="item in voteArr.titleList" :key="item.id">
                {{ item.name }}
              </li>
            </ul>
            <div class="shouqi" v-if="shouqi" @click.prevent="shouqiF(0)">
              <span class="shouq">收起选项</span>
              <van-icon class="vote_xs" name="arrow-up" />
            </div>
            <div class="shouqi" v-else @click.prevent="shouqiF(1)">
              <span class="shouq">展开选项</span>
              <van-icon class="vote_xs" name="arrow-down" />
            </div>
          </div>
          <topicOverview
            class="createTopicView_topicOverview"
            v-if="isForward"
            v-bind="topicOverviewDisplayData"
          />
          <div
            ref="createTopicView_imgList"
            class="createTopicView_imgList"
            v-if="formData.imageList && formData.imageList.length"
          >
            <div
              v-for="(imgUrl, index) in formData.imageList"
              :key="imgUrl"
              class="createTopicView_imgItem"
            >
              <img :src="imgUrl" class="imgItem_image" />
              <TopRightDeleteIcon @click.native.prevent="deleteImage(index)"></TopRightDeleteIcon>
            </div>
            <div
              class="flex-center-row add"
              @click.prevent="handleAddClick"
              v-if="formData.imageList && formData.imageList.length < 9"
            >
              <img src="/images/editTopicToolbar/add.png" class="addIcon" />
            </div>
          </div>
          <!-- 视频显示视频图片-->
          <VideoIndex @deleteVideo="deleteVideo" v-if="formData.videoAddr" :form-data="formData"></VideoIndex>
        </div>
      </div>
      <div class="kb"></div>
      <div v-if="!isCircle" class="tbzgc" @click="van_qglsz">
        <div class="qztx_type_box">
          <van-cell
            title="同步至我的圈子"
            is-link
          >
            <template #default>
              <span v-if="circleDetails.title" v-html="circleDetails.title">{{ circleDetails.title }}</span>
              <span v-else>请选择</span>
            </template>
          </van-cell>
          <!-- <p v-if="!qzTypeVal" class="p_titl">请选择</p>
          <span v-else class="qzTypeVal">{{ qzTypeVal }}</span> -->
        </div>
      </div>
      <div
        v-else
        class="syncSquare"
        @click="handleSyncSquareShow"
        v-show="this.$route.query.isPublic"
      >
        <div class="qztx_type_box">
          <van-cell
            title="同步至广场"
            :value="
              !circleDetails.isPublic
                ? '私密圈不能同步至广场'
                : isSquareShow
                ? '是'
                : noJustSend
                ? '否,仅发布在该圈子'
                : '请选择'
            "
            is-link
          />
          <!-- <p v-if="!qzTypeVal" class="p_titl">请选择</p>
          <span v-else class="qzTypeVal">{{ qzTypeVal }}</span> -->
        </div>
      </div>
      <van-action-sheet
        class="action"
        cancel-text="取消"
        v-model="syncSquareShow"
        :actions="actions"
        @select="onSelect"
      />
    </div>

    <!-- 匿名提示 -->
    <div v-if="!syncSquareShow">
      <div
        :class="{ hits: true, tpisIphoneUp: keyboardUp && isIPhonxPlus() }"
        v-if="tips"
      >
        <div class="hits-left">
          <div class="title"><span>提示</span></div>
          <div class="content">
            匿名不代表对自己的发言不负责任，请谨慎使用匿名功能哦~
          </div>
        </div>
        <div class="hits-close" @click="closeTips()">
          <div class="button">确定</div>
        </div>
      </div>
    </div>

    <!-- todo   编辑器下边的菜单-->
    <editTopicToolbar
      v-if="!syncSquareShow"
      showTopic
      showVideo
      showPublishBtn
      showToup
      @toup="handleToup"
      @img="handleAddImg"
      @publish="handlePublic"
      @topic="handleShowTag()"
      @relation="handleRelation()"
      showAnonymousCommentCheckBox
      :imageContent="formData.imageList.length"
      :videoCount="formData.videoAddr ? 1 : 0"
      :isAnonymousComment.sync="formData.anonymous"
      :class="{
        toolBarWrap: (keyboardUp || keyboardUp2 || keyboardUp3) && iosShow,
        iphoneXplus: keyboardUp && isIPhonxPlus()
      }"
    />

    <!-- todo   关联我加入的圈子-->
    <van-popup
      round
      :close-on-click-overlay="false"
      v-model="isShowSelectCircle"
      closeable
      :style="{ height: '90%' }"
      position="bottom"
    >
      <CircleIndex
        :selectItem="circleDetails"
        v-if="isShowSelectCircle"
        @change="handleChangeItem"
      ></CircleIndex>
    </van-popup>
    <!-- todo   投票-->
    <div class="vote-container">
      <van-popup
        title="添加投票"
        round
        closeable
        v-model="isVoteShow"
        position="bottom"
        :style="{ height: '90%' }"
      >
        <!--        v-if="isVoteShow"-->
        <vote
          ref="votePopup"
          v-if="isVoteShow"
          :editVoteData="voteArr"
          @handleVote="handleVote"
          @back="back" />
      </van-popup>
    </div>
    <!-- todo 选择@的人员-->
    <van-popup
      round
      v-model="showAtList"
      position="bottom"
      :style="{ height: '90%' }"
    >
      <Organization
        ref="orgRef"
        :is-show-top-confirm="true"
        :is-source-ai-te="true"
        v-if="showAtList"
        @close="showAtList = false"
        @submit="handleAddOrgMember"
        :have-member-list="selectMemberList"
        :select-member-list="selectMemberList"
      ></Organization>
    </van-popup>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import topicOverview from '@/components/topicItem/components/overview'
import editTopicToolbar from '@/components/editTopicToolbar'
import TopRightDeleteIcon from '@/components/topRightDeleteIcon'
import vote from '@/views/app/home/createTopic/vote'
import VideoIndex from '@/views/app/home/createTopic/video'
import {
  articleAdd as createArticle,
  detail as getArticleDetail
} from '@/api/article'
import {isVideo, vanFieldFormatter} from '@/utils/utils'
import {selectFile} from '@/utils/selectImg'
import {ADMIN_NAME} from '@/config/config'
import {getDictBiz} from '@/api/dict-biz'
import {mapGetters} from 'vuex'
import {isAndroid} from '@/utils/plamtom'
import Organization from '@/components/organization'
import CircleIndex from './circle/index'
import {setItem} from '@/utils/storage'
import {ImagePreview} from 'vant'
import {debounce} from '@/utils/debounce'
import {BR, DIV_END, DIV_START} from "@/config/config";
import AutoWidthInput from '@/components/autoWidthInput'

export default {
  props: {
    quoteId: String,
    selectMembers: {
      type: String,
      default: ''
    }
  },
  components: {
    CircleIndex,
    pageHead,
    topicOverview,
    editTopicToolbar,
    vote,
    Organization,
    AutoWidthInput,
    TopRightDeleteIcon,
    VideoIndex
  },
  data () {
    return {
      yaPeng: '',
      isShowSelectCircle: false, // 原 showPop
      articleDetail: {},
      selectMemberList: [],
      formData: {
        title: '',
        content: '',
        imageList: [],
        anonymous: false,
        videoAddr: '',
        tags: []
      },
      showCreateTag: false,
      createTagContent: '',
      attList: [],
      inputFocus: false,
      showLoading: false,
      types: [],
      type: '',
      tips: false,
      qzTypeVal: '',
      showPopCircle: false,
      isVoteShow: false,
      currentLength: 0,
      maxlength: 5000,
      maxHtml: '',
      topicList: [],
      topicObjList: [],
      keyObjValue: '1',
      topicList1: [],
      voteArr: {},
      titleLit: false,
      shouqi: false,
      keyboardUp: false,
      keyboardUp2: false,
      keyboardUp3: false,
      setTimeout: null,
      keyValue: 1,
      circleDetails: {},
      syncSquareShow: false,
      isSquareShow: true,
      actions: [{name: '是'}, {name: '否,仅发布在该圈子'}],
      isSelectCircle: false,
      isCircle: false,
      showAtList: false,
      iosShow: false,
      noJustSend: false,
      topicMaxTextList: []
    }
  },
  watch: {
    'formData.anonymous': {
      handler: function (val) {
        this.tips = val
      }
    },
    'formData.content' (oldValue, newValue) {
      // 当值变化了更新
      if (newValue !== this.$refs.inputs.innerHTML) {
        console.log(oldValue)
        this.updateContent(newValue)
      }
    }
  },
  beforeDestroy () {
    if (!this.isForward) {
      sessionStorage.setItem('topicDraft', JSON.stringify(this.formData))
    }
  },
  created () {
    this.appSource()
  },
  mounted () {
    this.updateContent(this.formData.content)
    if (this.$route.query.circleDetail) {
      this.isSquareShow = false
      this.isCircle = true
      this.circleDetails = JSON.parse(this.$route.query.circleDetail)
      document.title = `发布至圈子-${this.circleDetails.title}`
      if (!this.circleDetails.isPublic) {
        this.isSquareShow = false
      }
    }

    document.addEventListener('focusin', (e) => {
      if (this.excludeForceClass(e)) {
        return
      }
      this.inputFocus = true
      this.focusTime = new Date().getTime()
      if (this.sureCloseKeyBoard) {
        this.sureCloseKeyBoard = false
      }
    })
    document.addEventListener('focusout', (e) => {
      if (this.excludeForceClass(e)) {
        return
      }
      this.sureCloseKeyBoard = true
      setTimeout(() => {
        if (this.sureCloseKeyBoard) {
          this.inputFocus = false
        }
      }, 200)
    })

    if (this.isForward && this.quoteId) {
      this.getArticleDetail()
    }
    this.selectMemberList = this.selectMembers
      .split(',')
      .filter((item) => item)
      .map((item) => {
        const [id, name] = item.split(':')
        return {id, name}
      })
  },
  computed: {
    ...mapGetters(['articleTypes']),
    topicOverviewDisplayData () {
      let {content, title, imageList, userName, anonymous, superAdmin} =
      this.articleDetail || {}
      const [imgUrl] = (imageList || '').split(',')
      userName = anonymous ? '匿名' : superAdmin ? ADMIN_NAME : userName
      return {content, title, imgUrl, userName, anonymous, superAdmin}
    },
    isForward () {
      return !!this.quoteId
    }
  },
  methods: {
    // 过滤输入的数字
    formatterTitle (value) {
      let newValue = value.replace(/&/g, '')
      newValue = newValue.replace(/>/g, '')
      newValue = newValue.replace(/</g, '')
      return newValue
    },
    // 删除话题
    deleteTopic (i) {
      this.$refs.tagInput[i].innerText = ''
      if (!this.$refs.tagInput[i].innerText.trim()) {
        const parent = this.$refs.tagInput[i].parentNode
        if (parent) {
          parent.remove()
        }
        const arr = this.topicList.filter((item) => {
          if (item.trim()) {
            return true
          }
        })
        this.topicList = arr
        const arr1 = []
        this.$refs.tagInput.forEach((item) => {
          if (item.innerText.trim()) {
            arr1.push(item.innerText)
          }
        })
        this.topicList1 = arr1

        this.keyboardUp3 = false
        if (!this.topicList.length) {
          this.showCreateTag = false
        }
      }
    },
    previewImage () {
      this.$nextTick(() => {
        // 得到所有的 img 节点
        const articleContent = this.$refs['createTopicView_imgList']
        const imgs = articleContent.querySelectorAll('.imgItem_image')
        // 获取所有 img 地址
        const images = []
        imgs.forEach((img, index) => {
          console.log(img.src)
          console.log(img.src)
          images.push(img.src)

          // 给每个 img 注册点击事件，在处理函数中调用预览
          img.onclick = () => {
            ImagePreview({
              // 预览的图片地址数组
              images,
              maxZoom: 12,
              // 起始位置，从 0 开始
              startPosition: index
            })
          }
        })
      })
    },
    handleToup () {
      this.isVoteShow = !this.isVoteShow
      this.$refs.titleInput.blur()
      this.$refs.inputs.blur()
      this.$nextTick(() => {
        this.$refs.votePopup.editVote()
      })
    },
    handleSyncSquareShow () {
      if (!this.circleDetails.isPublic) {
        return
      }
      this.syncSquareShow = true
    },
    // 监听话题粘贴
    topicPaste (e, i) {
      // 阻止默认粘贴
      e.preventDefault()
      // clipboardData的getData(format) 从剪贴板获取指定格式的数据

      let text = e['originalEvent'] || e.clipboardData.getData('text/plain')
      console.log(text)
      // 过滤粘贴html标签
      var reg = /<[^<>]+>/g
      if (reg.test(text)) {
        document.execCommand('insertText', false, `${text}`)
        return
      }

      // 判断字数
      if (text.length + this.$refs.tagInput[i].innerText.length > 50) {
        text = text.substring(0, 50 - this.$refs.tagInput[i].innerText.length)
      }
      // 插入文本
      document.execCommand('insertHTML', false, `<span>${text}</span>`)
    },
    // 监听粘贴
    paste (e) {
      // 阻止默认粘贴
      e.preventDefault()
      // clipboardData的getData(format) 从剪贴板获取指定格式的数据

      let text = e['originalEvent'] || e.clipboardData.getData('text/plain')
      console.log(text)
      // 过滤粘贴html标签
      var reg = /<[^<>]+>/g
      if (reg.test(text)) {
        document.execCommand('insertText', false, `${text}`)
        return
      }
      console.log(this.$refs.inputs.innerText.length)
      // 判断字数
      if (text.length + this.$refs.inputs.innerText.length > this.maxlength) {
        text = text.substring(
          0,
          this.maxlength - this.$refs.inputs.innerText.length
        )
      }
      console.log(text)

      // 插入文本
      document.execCommand('insertHTML', false, `<span>${text}</span>`)
    },
    // 更新内容实现双向绑定
    updateContent (value) {
      this.$refs.inputs.innerHTML = value
    },
    handleRelation () {
      this.showAtList = true
      this.selectMemberList = []
    },
    handleAddOrgMember (list) {
      let userLabel = ''
      list.forEach((item) => {
        let a = `<a   href="#" class="label" type="1" identity="article" id="${item.id}" name="${item.name}">@${item.name}</a>`
        userLabel = userLabel + a
      })
      this.handleCursor(this.$refs.inputs)
      document.execCommand('insertHTML', false, userLabel)
      this.handleHtmlA()
      this.showAtList = false
    },
    // 处理a标签
    handleHtmlA () {
      let a = this.$refs.inputs.querySelectorAll('a')
      for (let i = 0; i < a.length; i++) {
        a[i].addEventListener('DOMSubtreeModified', this.watchAhtml)
      }
    },
    // 监听a标签变化进行操作
    watchAhtml (e) {
      // 得到a标签
      const a = e.target.parentElement
      if (a && a.name) {
        // 当节点的文本减少了那么将节点删除
        if (a.name.length > a.innerText.length - parseInt(a.type)) {
          a.remove()
        }
        // 当节点的文本增加了那么将节点变为文本节点
        if (a.name.length < a.innerText.length - parseInt(a.type)) {
          // 字数超限取消操作
          if (this.$refs.inputs.innerText.length > this.maxlength) {
            return
          }
          this.$refs.inputs.replaceChild(
            document.createTextNode(a.innerText),
            a
          )
          this.handleCursor(this.$refs.inputs)
        }
      }
    },
    blurEvent () {
      clearTimeout(this.setTimeout)
      this.setTimeout = setTimeout(() => {
        this.keyboardUp = false
      })
    },
    blurEvent2 () {
      clearTimeout(this.setTimeout)
      this.setTimeout = setTimeout(() => {
        this.keyboardUp2 = false
      })
    },
    onSelect (item) {
      if (item.name === '是') {
        this.syncSquareShow = false
        this.isSquareShow = true
      } else {
        this.isSquareShow = false
        this.noJustSend = true
        this.syncSquareShow = false
      }
    },
    van_qglsz () {
      this.isShowSelectCircle = true
    },
    handleCloseCircle () {
      this.isShowSelectCircle = false
    },
    excludeForceClass (e) {
      if (isAndroid()) {
        return true
      }
      let clazz = ''
      if (e.path) {
        clazz = e.path[0].className
      }
      // ios 下元素类位置
      if (e.srcElement) {
        clazz = e.srcElement._prevClass || ''
      }
      return clazz.indexOf('checkbox van-checkbox') > -1
    },
    closeTips () {
      this.tips = false
    },
    back () {
      this.isVoteShow = false
    },
    isVideo,
    fetchTypes () {
      if (this.articleTypes.length) {
        this.types = JSON.parse(JSON.stringify(this.articleTypes))
      } else {
        getDictBiz({code: 'article_category_type'}).then((res) => {
          if (res && res.length) {
            this.type = res[0].dictKey
            this.types = res.map((v, i) => {
              return {
                ...v,
                chosen: i === 0
              }
            })
            this.$store.commit('common/setArticleTypes', this.types)
          }
        })
      }
    },
    // handleBack() {
    //   if (!this.isForward) {
    //     const { title, content, imageList } = this.formData;
    //     if (title.length > 0 || content.length > 0 || imageList.length > 0) {
    //       this.$toast("已为您保存草稿");
    //     }
    //   }
    // },
    isIPhonxPlus () {
      return (
        /iphone/gi.test(navigator.userAgent) &&
        screen.height >= 896 &&
        screen.width === 414
      )
    },
    vanFieldFormatter,
    handleTitleInput () {
      this.formData.title = this.vanFieldFormatter(this.formData.title)
      if (this.formData.title.length >= 24) {
        this.$toast.fail({
          message: '帖子标题最多可输入24个字',
          position: 'top'
        })
      }
    },
    handleDelete (index) {
      this.selectMemberList.splice(index, 1)
      let str = ''
      this.selectMemberList.forEach((item) => {
        str = str + `${item.id}:${item.name}` + ','
      })
      if (str.endsWith(',')) {
        str = str.substring(0, str.length - 1)
      }
      this.$route.query.selectMembers = str
    },
    handleContentInput () {
      if (this.formData.content.length > 5000) {
        this.$toast.fail({
          message: '帖子长度最多可输入5000个字哦',
          position: 'top'
        })
      }
    },

    handleDeleteTag () {
      this.createTagContent = ''
      this.formData.tags[0] = this.createTagContent
    },
    // 展示话题，并新增一个
    handleShowTag () {
      // this.handleAddObjTag()
      this.keyValue++
      this.keyboardUp3 = true
      if (!this.showCreateTag) {
        this.showCreateTag = true
        this.$nextTick(() => {
          this.handleCursor(this.$refs.tagInput[0])
        })
      } else {
        this.handleAddTag()
      }
    },
    // 新增一个话题
    handleAddTag () {
      this.handleCreateTag()
      console.log(this.topicList)
      this.$nextTick(() => {
        this.handleCursor(this.$refs.tagInput[this.$refs.tagInput.length - 1])
      })
    },
    // 光标聚焦到底部
    handleInputFocus (i) {
      this.handleCursor(this.$refs.tagInput[i])
    },
    // 失去焦点
    handleBlur (i) {
      if (!this.$refs.tagInput[i].innerText.trim()) {
        const parent = this.$refs.tagInput[i].parentNode
        if (parent) {
          parent.remove()
        }
      }
      const res = this.$refs.tagInput.some((item) => {
        document.activeElement == item
      })
      if (!res) {
        this.keyboardUp3 = false
      }
    },
    handleKeydown (e, i) {
      // 阻止enter键
      if (e.keyCode === 13) {
        return e.preventDefault()
      }
      // 删除键
      if (e.keyCode === 8) {
        try {
          if (this.$refs.tagInput[i]) {
            if (!this.$refs.tagInput[i].innerText.trim()) {
              const parent = this.$refs.tagInput[i].parentNode
              if (parent) {
                parent.remove()
              }
              const arr = this.topicList.filter((item) => {
                if (item.trim()) {
                  return true
                }
              })
              this.topicList = arr

              this.keyboardUp3 = false
              if (!this.topicList.length) {
                this.showCreateTag = false
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    /**
     * 添加话题按钮
     */
    handleAddObjTag () {
      const valueNullList = this.topicObjList.filter(item => !item.value) || []
      if (valueNullList.length > 0) {
        this.$toast({
          message: '请先完善空话题再添加吧！',
          position: 'top'
        })
      } else {
        this.topicObjList.push({
          value: '',
          id: this.topicObjList.length + 1
        })
        this.$nextTick(() => {
          const lastInputId = 'topicFiled' + this.topicObjList.length
          const lastInputNode = document.getElementById(lastInputId)
          lastInputNode.focus()
        })
      }
    },
    // 键盘抬起
    handleInputFocusAuto () {
      this.keyboardUp3 = true
    },
    // 键盘关闭
    handleInputBlurAuto () {
      this.keyboardUp3 = false
    },
    // 话题字数超过限制
    handleInputObjTopic (value) {
      if (value.length >= 15) {
        this.$toast({
          message: '话题最多可输入15个字',
          position: 'top'
        })
      }
    },
    /**
     * 删除话题按钮
     */
    deleteObjTopic (id) {
      this.topicObjList = this.topicObjList.filter(item => item.id !== id) || []
    },
    // 通过节点生成标签
    handleCreateTag () {
      const arr = []
      this.$refs.tagInput.forEach((item) => {
        if (item.innerText.trim()) {
          arr.push(item.innerText)
        }
      })
      this.topicList = arr
    },
    // 发布帖子
    handlePublic: debounce(async function () {
      // this.handleCreateTag()
      const topicList = []
      const tagInputList = this.$refs.tagInput || []
      if (tagInputList.length > 0) {
        tagInputList.forEach((item) => {
          if (item.innerText.trim()) {
            topicList.push(item.innerText)
          }
        })
      }
      if (topicList.length > 0) {
        const textLimitList = topicList.filter(item => item.length > 50)
        if (textLimitList.length > 0) return this.$toast({
          message: '话题不能超过50个字！',
          position: 'top'
        })
      }
      let text = this.handleHtmlValue()
      text = this.vanFieldFormatter(text)
      this.keyboardUp = true
      const formData = {
        voteDTO: null
      }
      formData.circleId = this.circleDetails.id
      formData.content = text
      formData.title = this.formData.title
      formData.iaAnonymous = this.formData.anonymous
      formData.imageList = this.formData.imageList
      formData.isAddCircle = this.circleDetails.id ? true : false
      formData.isAddVote = JSON.stringify(this.voteArr) != '{}' ? true : false
      formData.isAddTopic = topicList.length > 0
      console.log(topicList)
      if (formData.isAddTopic) {
        formData.topicTitles = topicList
      } else {
        formData.topicTitles = []
      }
      // const valueNullList = this.topicObjList.filter(item => item.value) || []
      // formData.isAddTopic = valueNullList.length > 0
      // if (formData.isAddTopic) {
      //   const topicTitles = []
      //   valueNullList.forEach(item => {
      //     topicTitles.push(item.value)
      //   })
      //   formData.topicTitles = topicTitles
      // } else {
      //   formData.topicTitles = []
      // }
      formData.videoUrl = this.formData.videoAddr
      formData.isSquareShow = this.isSquareShow
      if (JSON.stringify(this.voteArr) !== '{}') {
        formData.voteDTO = {}
        formData.voteDTO.endTime = this.voteArr.time
        formData.voteDTO.maxOptionalCount = this.voteArr.changMore
          ? this.voteArr.changMore
          : 1
        formData.voteDTO.options = this.voteArr.titleList
          ? this.voteArr.titleList.map((item) => {
            return item.name
          })
          : []
        formData.voteDTO.title = this.voteArr.title
        formData.voteDTO.type = this.voteArr.type + 1
      }
      console.log(formData)

      if (!this.isForward) {
        if (!formData.title) {
          return this.$notify({type: 'warning', message: '请输入标题'})
        }
        if (!formData.content) {
          return this.$notify({type: 'warning', message: '请输入内容'})
        }
      }
      this.showLoading = true
      // const {
      //   isForward,
      //   quoteId,
      //   formData: { anonymous: isAnonymous, imageList, videoAddr } = {},
      //   selectMemberList
      // } = this
      // const attList = selectMemberList.map((item) => {
      //   const { id: attId, name: attName } = item
      //   return { attId, attName }
      // })
      // const anonymous = isAnonymous ? 1 : 0
      // const quoteType = isForward ? 2 : 1
      // let data = {}
      // if (this.$route.query.quoteId) {
      //   data = {
      //     content: this.formData.content,
      //     anonymous,
      //     quoteType,
      //     quoteId,
      //     imageList: imageList.join(','),
      //     attList,
      //     videoAddr,
      //     tags: this.formData.tags,
      //     categoryId: this.type
      //   }
      // } else {
      //   data = {
      //     ...this.formData,
      //     anonymous,
      //     quoteType,
      //     quoteId,
      //     imageList: imageList.join(','),
      //     attList,
      //     videoAddr,
      //     categoryId: this.type
      //   }
      // }
      createArticle(formData)
        .then((res) => {
          this.$notify({type: 'success', message: '发表成功'})
          this.formData = {
            title: '',
            content: '',
            imageList: [],
            anonymous: false,
            tags: [],
            videoAddr: ''
          }
          // 存储刚发布的帖子的数据，回到首页或者圈子详情页时锚点到刚发布的帖子
          if (res.articleId) {
            const timeStamp = new Date().getTime()
            const fromRouter = this.isCircle ? 'circle' : 'home'
            setItem('publishNewArticleDetail', {
              articleId: res.articleId,
              timeStamp: timeStamp,
              fromRouter: fromRouter
            })
          }
          localStorage.setItem('refreshFlag', true)
          this.showLoading = false
          let tags = res.similarTopicTitles || []
          tags = tags.filter((item) => item.trim())
          if (tags.length) {
            window.localStorage.setItem('tags', JSON.stringify(tags))
            this.$router.replace({
              name: 'topicCategory',
              query: {
                id: res.articleId
              }
            })
          } else {
            this.$router.back()
          }
        })
        .catch((err) => {
          this.showLoading = false
          if (err.code === 450) {
            this.$dialog
              .confirm({
                title: '提示',
                message: `${err.msg},请修改后再发布！`,
                cancelButtonText: '申诉'
              })
              .then(() => {
                // on confirm
              })
              .catch(() => {
                formData.checkStatus = 0
                createArticle(formData).then(() => {
                  this.$notify({type: 'success', message: '申诉成功~'})
                  this.formData = {
                    title: '',
                    content: '',
                    imageList: [],
                    anonymous: false,
                    tags: []
                  }
                  this.$router.back()
                })
              })
          }
        })
    }, 200),
    /**
     * 发布帖子时取出文本
     */
    handleHtmlValue () {
      // 深克隆一份编辑框容器
      let newInputBox = this.$refs.inputs.cloneNode(true)

      //  取出a标签的属性拼接成JSON字符串，将a节点元素替换为文本节点
      let a = newInputBox.querySelectorAll('.label')
      for (let i = 0; i < a.length; i++) {
        let JSON = `{"type":${a[i].type},"identity":"article","id":"${a[i].id}","content":"${a[i].name}"}`
        const parent = a[i].parentNode
        parent.replaceChild(document.createTextNode(JSON), a[i])
      }
      // div表示要换行的文本
      /*   let brs = newInputBox.querySelectorAll('br')
         for (let i = 0; i < brs.length; i++) {
           //  将div节点元素替换为文本节点
           const parent = brs[i].parentNode
           let JSON = `\n`
           parent.replaceChild(document.createTextNode(JSON), brs[i])
         }*/
      let divs = newInputBox.querySelectorAll('div')
      for (let i = 0; i < divs.length; i++) {
        //  将div节点元素替换为文本节点
        const parent = divs[i].parentNode
        let divText = divs[i].innerText
        if (!divText.trim()) {
          divText = BR
        }
        let text = `${DIV_START}${divText}${DIV_END}`
        parent.replaceChild(document.createTextNode(text), divs[i])
      }
      return newInputBox.textContent.trim()
    },
    /**
     * 备份Backups
     */
    handleHtmlValueBackups () {
      // 深克隆一份编辑框容器
      let newInputBox = this.$refs.inputs.cloneNode(true)

      //  取出a标签的属性拼接成JSON字符串，将a节点元素替换为文本节点
      let a = newInputBox.querySelectorAll('.label')
      for (let i = 0; i < a.length; i++) {
        let JSON = `{"type":${a[i].type},"identity":"article","id":"${a[i].id}","content":"${a[i].name}"}`
        const parent = a[i].parentNode
        parent.replaceChild(document.createTextNode(JSON), a[i])
      }
      // 处理标签内容为后端需要的字符串
      // div表示要换行的文本
      let div = newInputBox.querySelectorAll('div')
      for (let i = 0; i < div.length; i++) {
        //  将div节点元素替换为文本节点
        if (div[i].innerText) {
          const parent = div[i].parentNode
          let JSON = `\n${div[i].innerText}`
          parent.replaceChild(document.createTextNode(JSON), div[i])
        }
      }
      return newInputBox.textContent.trim()
    },
    async handleAddClick () {
      const files = await selectFile(false) || []
      if(files.length > 9 - this.formData.imageList.length){
        this.$toast.fail({
          message: '图片不能超过9张',
          position: 'top'
        })
        return
      }
      this.showLoading = true
      const filesRes = await this.$upload.multipleFilesUpload(files)
      this.showLoading = false
      this.handleAddImg(filesRes.map((item) => item.filename))
    },
    /**
     *
     * @param files
     * @returns {Promise<void>}
     */
    async handleAddImg (files) {
      let imageContent = this.formData.imageList.length
      let videoCount = this.formData.videoAddr ? 1 : 0
      files.forEach((item) => {
        if (isVideo(item)) {
          videoCount++
        } else {
          imageContent++
        }
      })
      if (videoCount > 0 && imageContent > 0) {
        this.$toast.fail({
          message: '不能同时发送图片和视频',
          position: 'top'
        })
      } else if (videoCount > 1) {
        this.$toast.fail({
          message: '只能发送一条视频',
          position: 'top'
        })
      } else if (imageContent > 9) {
        this.$toast.fail({
          message: '图片不能超过9张',
          position: 'top'
        })
      } else if (videoCount === 1) {
        this.formData.videoAddr = files[0]
      } else {
        this.formData.imageList = this.formData.imageList.concat(files)
      }
      // 初始化图片点击预览
      setTimeout(() => {
        this.previewImage()
      }, 0)
    },
    /**
     * 获取话题详情
     */
    async getArticleDetail () {
      const {quoteId: id} = this
      this.articleDetail = await getArticleDetail({id})
      this.type = this.articleDetail.categoryId
      this.types.forEach((item) => {
        item.chosen = item.dictKey === this.type
      })
    },
    deleteImage (index) {
      this.formData.imageList.splice(index, 1)
    },
    deleteVideo () {
      this.formData.videoAddr = ''
    },
    handleChangeItem (value) {
      if (!value) {
        this.circleDetails = {}
        this.circleDetails.id = ''
      } else {
        this.circleDetails = value
      }
      this.isShowSelectCircle = false
    },
    handleTypeChange (item) {
      if (item.chosen) return
      this.type = item.dictKey
      this.types.forEach((it) => {
        it.chosen = it.dictKey === item.dictKey
      })
    },
    handleChangeTopicSign (event, index) {
      event.preventDefault()
      if (event.target.value !== '#') {
        this.deleteTopic(index)
      }
    },
    // 光标聚焦到尾部
    handleCursor (element) {
      let range = document.createRange()
      range.selectNodeContents(element)
      range.collapse(false)
      let sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    },
    // 投票数据回调
    handleVote (item) {
      this.voteArr = item
      this.handleCloseVote()
    },
    // 关闭投票弹框
    handleCloseVote () {
      this.isVoteShow = false
    },
    // 删除投票
    removeVote () {
      this.$dialog
        .confirm({
          title: '删除投票',
          confirmButtonText: '确定',
          cancelButtonText: '再考虑下',
          message: '确定删除投票?'
        })
        .then(() => {
          this.voteArr = {}
          this.voteShow = false
        })
        .catch(() => {
        })
    },
    // 收起/ 放开
    shouqiF (v) {
      if (v === 0) {
        this.titleLit = false
        this.shouqi = false
      } else {
        this.shouqi = true
        this.titleLit = true
      }
    },
    appSource () {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        console.log('ios')
        this.iosShow = true
        // return "ios";
      } else {
        console.log('andriod')
        this.iosShow = false
        // return "andriod";
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.createTopicView_imgList {
  padding-top: 20px !important;
}

div[contenteditable]:focus {
  outline: var(--color1) solid thin !important;
  background-color: #fff !important;
}

::v-deep .comment-input-box {
  padding-top: 10px;

  a {
    cursor: text;
    color: #fe5800;
  }

  font {
    color: #292929 !important;
  }

  .user,
  .topices {
    color: #000;
  }

  .user:hover,
  .topices:hover {
    text-decoration: none;
  }
}

.comment-input-box[contenteditable]:empty:before {
  content: attr(placeholder);
  color: #c8c9d6;
}

.comment-input-box[contenteditable] {
  border-radius: 8px;
  text-align: left;
  cursor: text;
  font-size: 14px;
}

.comment-input-box[contenteditable]:focus {
  outline: var(--color1) solid thin !important;
  background-color: #fff !important;
}

.tag-input {
  display: inline;
  min-width: 2px !important;
}
</style>
<style lang="scss" scoped>
::v-deep {
  .van-popup__close-icon {
    z-index: 16;
  }
}

.createTopicView {
  background: white;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  transition: all 0.4s;

  .submitBtn {
    background-color: $mainColor;
    width: 52px;
    height: 28px;
    color: white;
    font-size: 14px;
    border-radius: 14px;
    text-align: center;
    line-height: 28px;
  }

  .kb {
    height: 10px;
    background: #f4f4f4;
  }

  .tbzgc {
    width: 100%;

    .van-cell {
      padding-left: 44px;
      padding-right: 20.75px;
      position: relative;

      &::before {
        content: url('~@/assets/imgs/circle/Slice56_4.svg');
        position: absolute;
        left: 14px;
      }
    }
  }

  .syncSquare {
    width: 100%;

    .van-cell {
      padding-left: 44px;
      padding-right: 20.75px;
      position: relative;

      &::before {
        content: url('~@/assets/imgs/circle/square.png');
        position: absolute;
        left: 14px;
      }
    }
  }

  &_editView {
    flex: 1;
    overflow-y: auto;

    .editView_box {
      padding: 0 20px;
    }

    .type-area {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid #f4f4f4;

      .type {
        padding: 4px 8px;
        font-size: 13px;
        color: $titleColor;

        &.is-chosen {
          background: rgba(254, 88, 0, 0.1);
          color: $mainColor;
          border-radius: 2px;
        }
      }
    }

    &_title {
      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      // border-bottom: solid 1px $lightGray;
    }

    input {
      font-weight: bold;
      border: none;
      outline: none;
      border-bottom: 1px solid #f4f4f4;
      width: 100%;
      padding: 13px 0 10px 0;
      font-size: 16px;

      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #6d7278;
      }
    }

    &_content {
      padding-bottom: 20px;
      margin-bottom: 20px;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1;
      // border-bottom: solid #F4F4F4 1px;

      .selectedUserList {
        display: flex;
        align-items: center;
        color: #6d7278;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f4f4;

        .memberList {
          border-radius: 4px;
          padding: 1px 2px;
          background-color: #f6f6f6;
          display: flex;
          flex-wrap: wrap;
          flex: 1;

          &_item {
            position: relative;

            .userTag {
              height: 20px;
              margin: 5px;
              font-size: 12px;
            }

            img {
              height: 6px;
              position: absolute;
              top: 3px;
              right: -2px;
            }
          }
        }

        img {
          height: 18px;
        }
      }

      .van-field {
        padding: 10px 0;
      }

      ::v-deep .van-field__control {
        -webkit-text-fill-color: initial !important;
      }

      .van-cell::after {
        display: none;
      }

      .tagList {
        font-size: 14px;
        color: $mainColor;

        .van-tag {
          margin-right: 5px;
          margin-bottom: 5px;
          color: $mainColor;
        }
      }

      .createTopicView_topicOverview {
        margin-bottom: 20px;
      }
    }

    .createTag {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      color: $mainColor;
      font-size: 14px;
      padding-top: 10px;
      word-break: break-all;

      .tagLeft {
        position: relative;
        margin-right: 5px;

        .van-field {
          padding: 0;
        }

        .delete-topic {
          position: relative;
          display: inline-block;
          width: 16px;
        }
      }

      input {
        max-width: 180px;
        border: none;
        font-size: 14px;
        color: $mainColor;
        padding: 4px 0;
        font-weight: normal;
      }

      input:disabled {
        background-color: transparent;
      }

      .button-container {
        margin-top: 5px;
        margin-left: 15px;
      }

      .van-button {
        margin-left: 5px;
        color: #f74f36;
        background-color: #f6f6f6;
        font-size: 13px;
        border: none;
        width: 63px;
        height: 24px;
      }
    }

    .newcreateTag {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      color: $mainColor;
      font-size: 14px;
      padding-top: 10px;
      word-break: break-all;

      .newtagLeft {
        margin-right: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 18px !important;
        box-sizing: border-box;
      }

      input {
        max-width: 180px;
        border: none;
        font-size: 14px;
        color: $mainColor;
        padding: 4px 0;
        font-weight: normal;
      }

      input:disabled {
        background-color: transparent;
      }

      .button-container {
        margin-top: 5px;
        margin-left: auto;
      }

      .van-button {
        margin-left: 5px;
        color: #f74f36;
        background-color: #f6f6f6;
        font-size: 13px;
        border: none;
        width: 63px;
        height: 24px;
      }
    }
  }

  &_imgList {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-top: 10px;

    .add {
      background-color: #f6f6f6;
      width: 100px;
      height: 100px;
      margin: 5px;
      line-height: 115px;
      text-align: center;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &_imgItem {
    width: 100px;
    height: 100px;
    padding-bottom: 2%;
    position: relative;
    margin: 5px;

    //> div {
    //  border: solid 1px #cccccc;
    //  height: 100%;
    //  border-radius: 6px;
    //  overflow: hidden;
    //}

    .imgItem_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .delete {
      width: 20px;
      position: absolute;
      right: -10px;
      top: -10px;
    }
  }

  .toolBarWrap {
    padding-top: 20px;
    padding-bottom: 400px;
  }

  .iphoneXplus {
    padding-bottom: 405px;
  }

  .addVote {
    width: 72px;
    line-height: 72px;
    text-align: center;
    border: 1px dashed #c8c8c8;
    border-radius: 2px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .hits {
    //position: fixed;
    position: relative;
    margin-bottom: 10px;
    margin-left: 15px;
    font-size: 13px;
    width: 261px;
    height: 107px;
    background: rgba(235, 100, 44, 0.1);
    border-radius: 10px 10px 10px 0px;
    display: flex;
    flex-direction: row;

    .hits-left {
      width: 162px;
      margin: 10px 15px;

      .title {
        color: #fe5800;
      }

      .content {
        margin-top: 10px;
        text-align: LEFT;
        line-height: 20px;
      }
    }

    .hits-close {
      width: 50px;
      height: 26px;
      background: #fe5800;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      position: absolute;
      right: 15px;
      bottom: 10px;
    }
  }
}

.vote_box {
  position: relative;
  margin: 0 20px;
  margin-top: 16px !important;
  margin-left: 0 !important;
  padding: 0 12px 11px;
  background: #f6f6f6;
  border-radius: 6px;
  //width: 345px !important;
  box-sizing: border-box;

  .bigBox {
    padding-bottom: 11.5px;
    border-bottom: 1px solid #e0e0e0;
  }

  .vote_til {
    padding-top: 18px;
    display: flex;
    justify-content: space-between;

    .til_1 {
      font-size: 16px;
      color: #000000;
    }
  }

  .vote_fod_til {
    font-size: 12px;
    color: #6d7278;
    margin-top: 8px;
  }

  .vote_lis_box {
    padding: 8px 0;
    max-height: 140px;
    overflow-y: scroll;

    & > li {
      height: 40px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      font-size: 16px;
      color: #000000;
      line-height: 40px;
      text-indent: 11px;
      margin-bottom: 8px;
    }
  }

  .shouqi {
    color: #999999;

    .shouq {
      font-size: 14px;
    }

    .vote_xs {
      font-size: 14px;
    }
  }
}
</style>
